<template>
  <div class="news-containner">
    <news-info :news_info="news_info"></news-info>
  </div>
</template>
<script>
import NewsInfo from "../components/NewsInfo.vue";
export default {
  name: "NewsInfo",
  props: {},
  data() {
    return {
      news_info:{}
    };
  },
  methods: {},
  components: {
    "news-info": NewsInfo,
  },
  mounted() {
    this.api.getNewInfo({ id: this.$route.params.id }).then((res) => {
      if (res.code == 200) {
        this.news_info = res.data;
      }
    });
  },
};
</script>
<style lang="less" scoped>
.news-containner {
  margin-top: 100px;
  min-height: 1560px;
}
</style>
