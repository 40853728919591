<template>
  <div class="cx-new-info" :class="is_mobile ? 'm-cx-new-info' : 'pc-cx-new-info'">
    <div class="cx-ni-title">
      {{ news_info[$t("language.shot_name") + "_title"] }}
    </div>
    <div class="cx-ni-date">{{ news_info.input_time_date }}</div>
    <div class="cx-ni-desc">{{ news_info[$t("language.shot_name") + "_desc"] }}</div>
    <div
      class="cx-context"
      v-html="news_info[$t('language.shot_name') + '_content']"
    ></div>
  </div>
</template>
<script>
export default {
  name: "NewItem",
  props: {
    news_info: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  watch: {
    news_info() {
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
  },
  methods: {},
  components: {},
  mounted() {},
};
</script>
<style>
.cx-new-info {
  width: 765px;
  overflow: hidden;
  margin-right: 67px;
  color: #fff;
}
.cx-ni-title {
  font-size: 40px;
  line-height: 54px;
  text-align: left;
}
.cx-ni-date {
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
  font-weight: 400;
  margin: 19px 0px 13px;
  text-align: left;
}
.cx-context {
  text-align: left;
  margin-top: 20px;
  line-height: 34px !important;
  font-size: 18px !important;
}
.cx-context span p img {
  width: 100% !important;
  height: auto;
}
.cx-context span{
  line-height: 34px !important;
  font-size: 18px !important;
}

.cx-context p{
  line-height: 34px !important;
  font-size: 18px !important;
}
.cx-ni-desc {
  margin-right: 20px;
  font-size: 14px;
  padding-bottom: 20px;
  border-bottom: rgba(255, 255, 255, 1) solid 1px;
  text-align: left;
  line-height: 28px;
}
.m-cx-new-info {
  width: 623px;
  margin: 0 auto;
}

.m-cx-new-info .cx-ni-title {
  text-align: center;
}
.m-cx-new-info .cx-ni-date {
  text-align: center;
}
.m-cx-new-info .cx-ni-desc {
  text-align: center;
}

.cx-new-info img,.cx-new-info video {
  width: 100% !important;
  height: auto !important;
  margin: 20px auto;
}
</style>
